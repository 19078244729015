import { defineStore } from "pinia";

export const useNavigationStore = defineStore("navigation", {
  state: () => ({
    collapsed: false
  }),
  actions: {
    setCollapsed(collapsed: boolean) {
      this.collapsed = collapsed;
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    }
  }
});
